<template>
  <div :class="['players-windows-controller', rootClasses]">
    <BattlesPlayerWindow
      v-for="player in players"
      :key="player.slot"
      :animator="battleAnimator"
      :battle-state="battleState"
      :buttons-state="personButtonsState"
      :has-players="hasPlayers"
      :is-host="player.host"
      :is-round-won="player.round.won"
      :multicast-mode="battleActiveMultifix"
      :is-draw="!!battle?.draw"
      :ready="player.info?.ready"
      :is-won="player.won"
      :is-player-lose="loseInLastRound(player)"
      :is-me="!!userStore.userId && player.info?.userId === userStore.userId"
      :is-interrupted="processState.isInterruptedByEmptyNextRound"
      :is-not-ready-to-start="processState.isBattleToBeStarted"
      :is-battle-at-start-point="processState.isBattleAtStartPoint"
      :player="player.info"
      :player-drops="player.drops"
      :player-state="player.state"
      :player-offer="player.offer"
      :player-drops-sum="player.total"
      :player-temp-win="player.total === playersStore.highestTempWin"
      :player-total-won="battle!.total"
      :round-drops="player.round.drops"
      :spinner-items="activeRoundItems"
      :round-prizes-sum="activeRoundPrizesSum"
      :is-leader="isLeaderNow(player.slot)"
      :emojies="battlesEmojiesStore.getEmojiesList(EmojiesListsKeys.BATTLE_WINDOW, player.info?.id)"
      @round-next-item="handleNextItem(player.slot)"
      @round-end-spin="handleEndSpin(player.slot)"
      @round-end="playersStore.endRound(player.slot)"
      @send-emodji="playersStore.sendDebouncedEmoji($event, EmojiesListsKeys.BATTLE_WINDOW)"
      @join="handleJoin(player.slot)"
      @leave="handleLeave"
      @start="handleStartNow"
      @offer-start="handleOfferStartNow"
      @start-bots="handleStartBots"
      @offer-start-bots="handleOfferStartWithBots"
      @cancel-offer="handleCancelOffer"
      @start-round="isStartRound = true"
      @on-emoji-ends="battlesEmojiesStore.deleteEmoji($event, EmojiesListsKeys.BATTLE_WINDOW)"
      @drop-volume="(volume) => handlerVolume(volume)"
      @start-fan="handleFanAnimationStart"
      @start-circle="handleEndMultianimation"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import type { IPlayerInfo } from '../../types/battlePlayers.types';
import { BattlesSpinnerTimeouts } from '../../constants/rules';
import type { TVolumeDrop } from '../../components/PlayerWindow/PlayerWindow.types';
import { useSingleBattleStore } from '~/features/battles/store/singleBattle.store';
import { usePlayersStore } from '~/features/battles/store/players.store';
import { useVolumeStore } from '~/store/volume/volume';
import { volumesKeys } from '~/store/volume/volumes.data';
import { useUserStore } from '~/store/user/user.store';
import { useAlertStore } from '~/store/alert/alert.store';
import { useBattlesEmojiesStore } from '~/features/battles/store/battlesEmojies.store';
import { EmojiesListsKeys } from '~/features/battles/constants/emojies';
import { BattleEvents } from '~/repository/amplitude/events/battle';

const volumeStore = useVolumeStore();

const playersStore = usePlayersStore();
const singleBattleStore = useSingleBattleStore();
const userStore = useUserStore();
const alertStore = useAlertStore();
const battlesEmojiesStore = useBattlesEmojiesStore();

const { players, personButtonsState } = storeToRefs(playersStore);
const {
  battleActiveMultifix,
  activeRoundItems,
  activeRoundPrizesSum,
  battle,
  battleRounds,
  battleState,
  battleAnimator,
  processState,
} = storeToRefs(singleBattleStore);

const slotVolume = ref(0);

const hasPlayers = computed(() => battle.value!.members.length > 1);

const rootClasses = computed(() => ({
  'players-windows-controller__two': players.value?.length === 2,
}));

const { t } = useI18n();

const playSingleSound = (slot: number, volumeKey: string) => {
  if (slotVolume.value && slotVolume.value !== slot) return;

  slotVolume.value = slot;
  volumeStore.playVolume(volumeKey);
};

// определение лидера в текущий момент
const isLeaderNow = computed(() => {
  return (slot: number) => {
    const totalSums = players.value.map((el) => el.total);
    const maxTotalSums = Math.max(...totalSums);

    return players.value.find((el) => el.slot === slot)?.total === maxTotalSums;
  };
});

const handleJoin = async (playerSlot: number) => {
  if (userStore.user && battle.value && parseFloat(userStore.user.finance.balance) >= battle.value.price) {
    BattleEvents.participantEntered({ 'Battle Price': battle.value.price, 'Rounds': battleRounds.value.length });
    await playersStore.join(playerSlot);
  } else {
    alertStore.showError({
      message: t('battles.error.notEnoughBalance'),
      title: t('battles.error.notEnoughBalanceTitle'),
    });
  }
};

const handleLeave = async () => {
  if (battle.value && battleState.value.created) {
    personButtonsState.value.leaving = true;
    if (playersStore.me?.host && battle.value.members.length === 1) {
      singleBattleStore.cancelByHost = true;
    }
    await singleBattleStore.leaveBattle(battle.value.ulid, battle.value.price).catch(() => {
      personButtonsState.value.leaving = false;
    });
  }

  const localeRoute = useLocaleRoute();
  await navigateTo(localeRoute(ROUTING.BATTLES.MAIN));
};

/* если последний раунд был для определения рандомного победителя и игрок проиграл */
const loseInLastRound = (player: IPlayerInfo) => {
  const lastPlayers = singleBattleStore.memberInLastRound();
  const isPlayerInLast = lastPlayers?.find((playerID) => playerID === player.info?.id);
  return Boolean(isPlayerInLast);
};

const handleStartBots = () => {
  BattleEvents.earlyBotsGameStarted();
  playersStore.start(true);
};

const handleStartNow = () => {
  BattleEvents.earlyGameStarted();
  playersStore.start(false);
};

const handleOfferStartNow = () => {
  BattleEvents.earlyGameSuggested();
  playersStore.start(false);
};

const handleOfferStartWithBots = () => {
  BattleEvents.earlyBotsGameSuggested();
  playersStore.start(true);
};

const handleCancelOffer = () => {
  BattleEvents.suggestionCanceled();
  playersStore.cancel();
};
const handleNextItem = (slot: number) => {
  if (!volumesKeys.caseScroll) return;
  playSingleSound(slot, volumesKeys.caseScroll);
};

const handleEndSpin = (slot: number) => {
  if (!volumesKeys.caseReveal) return;
  playSingleSound(slot, volumesKeys.caseReveal);
};

const handlerVolume = (volume: TVolumeDrop) => {
  if (volume === 'show') volumeStore.playVolume(volumesKeys.dropShow);
  if (volume === 'down') volumeStore.playVolume(volumesKeys.dropDown);
};

const handleFanAnimationStart = () => {
  volumeStore.playVolume(volumesKeys.multicastFan);
};

const handleEndMultianimation = () => {
  volumeStore.playVolume(volumesKeys.multicastDrop);
};

onMounted(() => {
  volumeStore.setAudioVolume(0.1);
  volumeStore.loadVolume(volumesKeys.multicastDrop);
  volumeStore.loadVolume(volumesKeys.multicastFan);
  volumeStore.loadVolume(volumesKeys.dropShow);
  volumeStore.loadVolume(volumesKeys.dropDown);
});

// переменная видит старт первой рулетки, потом от старта рулетки для всех асинхронных рулеток можно одновременно показать круги, прокинув пропс
const isStartRound = ref<boolean>(false);
watch(
  isStartRound,
  (val) => {
    if (val) setTimeout(() => (isStartRound.value = false), BattlesSpinnerTimeouts.defaultSpinner);
  },
  { immediate: true },
);
</script>

<style src="./PlayerWindowsController.scss" scoped lang="scss" />
