<template>
  <div class="container-fan">
    <SharedKitDropItem
      v-if="drops.length >= 4"
      :key="props.drops[3].id"
      :name="props.drops[3].name"
      :image="declareImageOnItem(props.drops[3])"
      :color="declareColorOfItem(props.drops[3])"
      :price="String(props.drops[3].price)"
      class="item fourth-item"
      :class="[`fourth-item_variant-${props.drops.length}`, itemClasses]"
    />

    <SharedKitDropItem
      v-if="drops.length >= 3"
      :key="props.drops[2].id"
      :name="props.drops[2].name"
      :image="declareImageOnItem(props.drops[2])"
      :color="declareColorOfItem(props.drops[2])"
      :price="String(props.drops[2].price)"
      class="item third-item"
      :class="[`third-item_variant-${props.drops.length}`, itemClasses]"
    />

    <SharedKitDropItem
      v-if="drops.length >= 2"
      :key="props.drops[1].id"
      :name="props.drops[1].name"
      :image="declareImageOnItem(props.drops[1])"
      :color="declareColorOfItem(props.drops[1])"
      :price="String(props.drops[1].price)"
      class="item second-item"
      :class="[`second-item_variant-${props.drops.length}`, itemClasses]"
    />

    <SharedKitDropItem
      v-if="drops.length >= 1"
      :key="props.drops[0].id"
      :name="props.drops[0].name"
      :image="declareImageOnItem(props.drops[0])"
      :color="declareColorOfItem(props.drops[0])"
      :price="String(props.drops[0].price)"
      class="item first-item"
      :class="[`first-item_variant-${props.drops.length}`, itemClasses]"
    />
  </div>
</template>

<script setup lang="ts">
import type { IMulticastFanProps, IMultiFanEmits } from './MulticastFan.types';
import type { IRoundSkin } from '~/features/battles/types/battlesStore.types';
import { BattlesMulticastTimeouts } from '~/features/battles/constants/rules';
import type { ISimplifiedPreviewItem } from '~/features/cases/types/case.types';

const props = withDefaults(defineProps<IMulticastFanProps>(), {});
const emits = defineEmits<IMultiFanEmits>();

let fanTimeout: ReturnType<typeof setTimeout>;

const isFinalDropAnimation = ref(false);

const itemClasses = computed(() => ({
  'hide-drop': isFinalDropAnimation.value,
}));

const declareImageOnItem = (item: IRoundSkin | ISimplifiedPreviewItem) => {
  return (item as ISimplifiedPreviewItem).image || (item as IRoundSkin).image || '';
};

const declareColorOfItem = (item: IRoundSkin | ISimplifiedPreviewItem) => {
  return GlobalUtils.Colors.getColorsRarity(
    (item as ISimplifiedPreviewItem).quality || (item as IRoundSkin).quality || '',
  );
};

onMounted(() => {
  emits('start-fan');
  fanTimeout = setTimeout(() => {
    isFinalDropAnimation.value = true;
  }, BattlesMulticastTimeouts.fanTimeAll);
});

onUnmounted(() => {
  clearTimeout(fanTimeout);
});
</script>

<style src="./MulticastFan.scss" scoped lang="scss"></style>
