const tastydrop = {
  default: {
    SEO: {
      domain: 'Tastydrop.me',
    },
    colorPalette: {
      text: '#ffffff',
      mainColors: {
        primary: {
          50: '#8e60ee',
          100: '#713dd1',
          200: '#512a96',
          300: '#3f1f78',
          400: '#331862',
          500: '#2a1051',
          600: '#200e3d',
          700: '#1a0a34',
          800: '#15072b',
          900: '#0F0422',
        },
      },
      skinsRarity: {
        ultra: '#00C487',
        arcana: '#8bcc47',
        common: '#92a4b9',
        immortal: '#e8c03c',
        legendary: '#d32de6',
        mythical: '#8847ff',
        rare: '#4b69ff',
        uncommon: '#5b94d3',
      },
      userLevels: {
        1_4: ['#737785'],
        5_9: ['#573B93'],
        10_14: ['#7F11C2'],
        15_19: ['#6439DE'],
        20_29: ['#2F64ED'],
        30_39: ['#00ABAB'],
        40_49: ['#00B960'],
        50_59: ['#7DA608'],
        60_69: ['#CAA832'],
        70_79: ['#E18700'],
        80_89: ['#E76100'],
        90_99: ['#EF2222'],
        100_109: ['#F12393'],
        110_119: ['#DC68D0'],
        120_129: ['#DC68D0', '#2F64ED', '#8FA8E9'],
        130_139: ['#DC68D0', '#00ABAB', '#6CCBCB'],
        140_149: ['#DC68D0', '#00B960', '#71D1A3'],
        150_159: ['#DC68D0', '#7DA608', '#B3CE67'],
        160_169: ['#DC68D0', '#CAA832', '#D6C17A'],
        170_179: ['#DC68D0', '#E18700', '#E0B77A'],
        180_189: ['#DC68D0', '#E76100', '#FF9950'],
        190_199: ['#DC68D0', '#E76100', '#FF9950'],
        200: ['#DDC0DA', '#F12393', '#DDC0DA'],
      },
      caseCard: {
        circleBackground: 'radial-gradient(50% 50% at 50% 50%, #331862 87.5%, #8E60EE 100%)',
        circleShadow: '#713DD1',
      },
      battlepass: {
        classic: {
          locked: '#1B1D2B',
          done: {
            colorStops: ['#014F25 0%', '#1B1D2B 54.17%'],
            toDirection: '180deg',
          },
          level: '#33313D',
        },
        rare: {
          default: {
            colorStops: ['#0D7831 0%', '#0B2421 50%', '#064133 100%'],
            toDirection: '180deg',
          },
          current: {
            colorStops: ['#8BCC47 0%', '#0C3530 50%', '#0E5F3D 100%'],
            toDirection: '180deg',
          },
          shadow: '#7AC915',
          level: {
            colorStops: ['#8BCC47 0%', '#003939 100%'],
            toDirection: '225deg',
          },
        },
        ultra: {
          default: {
            colorStops: ['#978430 0%', '#1E160F 50%', '#483B0E 100%'],
            toDirection: '180deg',
          },
          current: {
            colorStops: ['#E3C74A 0%', '#3C311E 50%', '#71591B 100%'],
            toDirection: '180deg',
          },
          shadow: '#DBB023',
          level: {
            colorStops: ['#D0BA55 0%', '#816F20 100%'],
            toDirection: '225deg',
          },
        },
      },
    },
  },
  event: {
    coinIcon: 'SvgoNy23Coin',
    coinIconUrl: 'https://tastydrop.cc/img/event/xmas23/newCoin.svg',
    textCoinsColor: '#FF4853',
    buttonsColors: {
      mainGradient: ['#3592E8', '#00C9B1'],
    },
  },
};

const tastygo = {
  default: {
    SEO: {
      domain: 'Tastystrike.gg',
    },
    colorPalette: {
      text: '#ffffff',
      mainColors: {
        primary: {
          50: '#8e60ee',
          100: '#713dd1',
          200: '#512a96',
          300: '#3f1f78',
          400: '#331862',
          500: '#2a1051',
          600: '#200e3d',
          700: '#1a0a34',
          800: '#15072b',
          900: '#0F0422',
        },
      },
      skinsRarity: {
        ultra: '#00C487',
        arcana: '#8bcc47',
        common: '#92a4b9',
        immortal: '#e8c03c',
        legendary: '#d32de6',
        mythical: '#8847ff',
        rare: '#4b69ff',
        uncommon: '#5b94d3',
      },
      userLevels: {
        1_4: ['#737785'],
        5_9: ['#573B93'],
        10_14: ['#7F11C2'],
        15_19: ['#6439DE'],
        20_29: ['#2F64ED'],
        30_39: ['#00ABAB'],
        40_49: ['#00B960'],
        50_59: ['#7DA608'],
        60_69: ['#CAA832'],
        70_79: ['#E18700'],
        80_89: ['#E76100'],
        90_99: ['#EF2222'],
        100_109: ['#F12393'],
        110_119: ['#DC68D0'],
        120_129: ['#DC68D0', '#2F64ED', '#8FA8E9'],
        130_139: ['#DC68D0', '#00ABAB', '#6CCBCB'],
        140_149: ['#DC68D0', '#00B960', '#71D1A3'],
        150_159: ['#DC68D0', '#7DA608', '#B3CE67'],
        160_169: ['#DC68D0', '#CAA832', '#D6C17A'],
        170_179: ['#DC68D0', '#E18700', '#E0B77A'],
        180_189: ['#DC68D0', '#E76100', '#FF9950'],
        190_199: ['#DC68D0', '#E76100', '#FF9950'],
        200: ['#DDC0DA', '#F12393', '#DDC0DA'],
      },
      caseCard: {
        circleBackground: 'radial-gradient(50% 50% at 50% 50%, #331862 87.5%, #8E60EE 100%)',
        circleShadow: '#713DD1',
      },
      battlepass: {
        classic: {
          locked: '#1B1D2B',
          done: {
            colorStops: ['#014F25 0%', '#1B1D2B 54.17%'],
            toDirection: '180deg',
          },
          level: '#33313D',
        },
        rare: {
          default: {
            colorStops: ['#0D7831 0%', '#0B2421 50%', '#064133 100%'],
            toDirection: '180deg',
          },
          current: {
            colorStops: ['#8BCC47 0%', '#0C3530 50%', '#0E5F3D 100%'],
            toDirection: '180deg',
          },
          shadow: '#7AC915',
          level: {
            colorStops: ['#8BCC47 0%', '#003939 100%'],
            toDirection: '225deg',
          },
        },
        ultra: {
          default: {
            colorStops: ['#978430 0%', '#1E160F 50%', '#483B0E 100%'],
            toDirection: '180deg',
          },
          current: {
            colorStops: ['#E3C74A 0%', '#3C311E 50%', '#71591B 100%'],
            toDirection: '180deg',
          },
          shadow: '#DBB023',
          level: {
            colorStops: ['#D0BA55 0%', '#816F20 100%'],
            toDirection: '225deg',
          },
        },
      },
    },
  },
  event: {
    coinIcon: 'SvgoNy23Coin',
    coinIconUrl: 'https://tastydrop.cc/img/event/xmas23/newCoin.svg',
    textCoinsColor: '#FF4853',
    buttonsColors: {
      mainGradient: ['#3592E8', '#00C9B1'],
    },
  },
};

const appConfig = {
  tastydrop,
  tastygo,
};

export default appConfig;
